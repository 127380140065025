import { createAction, props } from '@ngrx/store';
import { IContractData, ICustomerData, ITaskDetails } from '@models/customer';
import { TaskTypeEnum } from '../../core/enums';

export enum EBoardItemsActions {
    SetBoardItem = '[BoardItem] - Set BoardItem new',
    SetCustomerContractsMap = '[CustomerContractsMap] - Set CustomerContractsMap',
    SetCurrentCustomer = '[CurrentCustomer] - Set CurrentCustomer',
    SetCurrentCustomers = '[CurrentCustomers] - Set CurrentCustomers',
    SetContractDetails = '[ContractDetails] - Set ContractDetails',
    SetContracts = '[Contracts] - Set Contracts',
    SetTaskDetails = '[TaskDetails] - Set TaskDetails',
    SetAdditionalData = '[AdditionalData] - Set AdditionalData',
    SetCurrentContactId = '[CurrentContactId] - Set CurrentContactId',
    SetOutgoingContactId = '[OutgoingContactId] - Set OutgoingContactId',
    SetBoardItems = '[BoardItems] - Set BoardItems',
    SetBoardItemIsMinimized = '[BoardItemIsMinimized] - Set BoardItemIsMinimized',
    SetCurrentTaskType = '[SetCurrentTaskType] - Set SetCurrentTaskType',
    RemoveTask = '[RemoveTask] - Remove Task By ContactId - RemoveTask',
    FetchCurrentCustomer = '[CurrentCustomer] - Fetch CurrentCustomer',
    FetchContractDetails = '[ContractDetails] - Fetch ContractDetails',
    FetchTaskDetails = '[TaskDetails] - Fetch TaskDetails',
}

export const SetBoardItem = createAction(
  EBoardItemsActions.SetBoardItem,
  props<{ payload: string }>()
);

export const SetCustomerContractsMap = createAction(
  EBoardItemsActions.SetCustomerContractsMap,
  props<{ payload: { key: string, customerContractsMap: Array<{
    powercloud_customer_id: string,
    powercloud_contract_ids: string[],
  }>} }>()
);

export const SetCurrentCustomer = createAction(
  EBoardItemsActions.SetCurrentCustomer,
  props<{ payload: { currentCustomer: ICustomerData } }>()
);

export const SetCurrentCustomers = createAction(
  EBoardItemsActions.SetCurrentCustomers,
  props<{ payload: { key: string, currentCustomers: Array<ICustomerData> }}>()
);

export const SetContractDetails = createAction(
  EBoardItemsActions.SetContractDetails,
  props<{ payload: { contractDetails: IContractData } }>()
);

export const SetContracts = createAction(
  EBoardItemsActions.SetContracts,
  props<{ payload: { key: string, contracts: Array<IContractData> } }>()
);

export const SetTaskDetails = createAction(
  EBoardItemsActions.SetTaskDetails,
  props<{ payload: { key: string, taskDetails: ITaskDetails<any> } }>()
);

export const SetAdditionalData = createAction(
  EBoardItemsActions.SetAdditionalData,
  props<{ payload: { key: string, additionalData: any } }>()
);

export const SetCurrentContactId = createAction(
  EBoardItemsActions.SetCurrentContactId,
  props<{ payload: string }>()
);

export const SetOutgoingContactId = createAction(
  EBoardItemsActions.SetOutgoingContactId,
  props<{ payload: { key: string, outgoingContactId: string } }>()
);

export const SetCurrentTaskType = createAction(
  EBoardItemsActions.SetCurrentTaskType,
  props<{ payload: { key: string, taskType: TaskTypeEnum } }>()
);

export const SetBoardItems = createAction(
  EBoardItemsActions.SetBoardItems,
  props<{ payload: string }>()
);

export const SetBoardItemIsMinimized = createAction(
  EBoardItemsActions.SetBoardItemIsMinimized,
  props<{ payload: { key: string, isMinimized: boolean } }>()
);

export const RemoveTask = createAction(
  EBoardItemsActions.RemoveTask,
  props<{ payload: string }>()
);

export const FetchCurrentCustomer = createAction(
  EBoardItemsActions.FetchCurrentCustomer,
  props<{ payload: { customerId: string, contactId: string } }>()
);

export const FetchContractDetails = createAction(
  EBoardItemsActions.FetchContractDetails,
  props<{ payload: { contractId: string, contactId: string, intent: string } }>()
);

export const FetchTaskDetails = createAction(
  EBoardItemsActions.FetchTaskDetails,
  props<{ payload: { taskId: string, contactId: string } }>()
);
