import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IBotConversationMessage, IConnectTokenReqBody} from '@models/connect';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, from, Observable, Subject} from 'rxjs';
import {Auth} from 'aws-amplify';
import {switchMap} from 'rxjs/operators';
import {environment} from '@env';
import {IApiResponse} from '@models/http';
import {IContractData, ICustomerData, ITaskDetails} from '@models/customer';
import {ApiService} from 'src/app/views/main/connect/services/api-service/api.service';
import {ISearchData} from '@models/board-items';
import 'amazon-connect-streams';
import AgentStateDefinition = connect.AgentStateDefinition;
import ContactStateType = connect.ContactStateType;
import {SetAllContacts} from '@store/actions/call.actions';
import {Store} from '@ngrx/store';
import {SideNavState} from '@store/state/side-nav.state';

@Injectable({
  providedIn: 'root'
})
export class ConnectService {

  activeContactAttributes: connect.AttributeDictionary;
  activeInboundContact: connect.Contact;
  activeOutboundContact: connect.Contact;
  justLoggedIn = false;
  private contacts: connect.Contact[];

  constructor(
    private httpClient: HttpClient,
    private translateService: TranslateService,
    private apiService: ApiService,
    private _store: Store<{ sideNav: SideNavState }>,
  ) {}

  getAgentLastStatus(params): Observable<any> {
    return this.httpClient.post<IApiResponse<IContractData>>('/ui_get_agent_last_status', params);
  }

  getCustomerDetails(customerId: string): Observable<IApiResponse<ICustomerData>> {
    const params = this.generateUrlParams([
      {key: 'CUSTOMERID', value: customerId},
      {key: 'LANGUAGE', value: this.translateService.currentLang || this.translateService.defaultLang}
    ]);
    return this.httpClient.get<IApiResponse<ICustomerData>>('/ui_fetch_customers_data', {params});
  }

  getContractData(contractId: string, intent?: string): Observable<IApiResponse<IContractData>> {
    const params = this.generateUrlParams([
      {key: 'CONTRACTID', value: contractId},
      {key: 'LANGUAGE', value: this.translateService.currentLang || this.translateService.defaultLang},
      {key: 'INTENT', value: intent}
    ]);
    return this.httpClient.get<IApiResponse<IContractData>>('/ui_fetch_contracts_data', {params});
  }

  getTaskData(taskId: string): Observable<IApiResponse<ITaskDetails<any>>> {
    return this.httpClient.post<IApiResponse<ITaskDetails<any>>>('/fetch_read_model', {
      id: taskId,
      language: this.translateService.currentLang
    });
  }

  getBotConversation(sessionId: string): Observable<IApiResponse<IBotConversationMessage[]>> {
    return this.httpClient.post<IApiResponse<IBotConversationMessage[]>>('/fetch_bot_interactions', {sessionId});
  }

  getCognitoUser(): Observable<any> {
    return from(Auth.currentSession())
      .pipe(
        switchMap((session) => {
          const body: IConnectTokenReqBody = {
            InstanceId: environment.instanceId,
            agentName: session.getIdToken().decodePayload()['cognito:username']
          };
          return this.httpClient.post('/connect_token', body);
        })
      );
  }

  setActiveInboundContact(contact: connect.Contact): void {
    this.activeInboundContact = contact;
  }

  setActiveOutboundContact(contact: connect.Contact): void {
    this.activeOutboundContact = contact;
  }

  getInitialAttributes(initialContactId: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.AWS.connect.instanceUrl}/contact/attributes/${environment.instanceId}/${initialContactId}`)
  }

  private generateUrlParams(arr: { key: string, value: string }[]): HttpParams {
    let params = new HttpParams();

    arr.forEach((item) => {
      params = params.append(item.key, item.value);
    });

    return params;
  }

  getSearchValue(params: string): Observable<{ data: { records: ISearchData[] } }> {
    return this.apiService.get(params);
  }

  updateContacts(contacts: connect.Contact[]): void {
    this.contacts = contacts.filter(contact => {
      return (contact.getState()?.type === ContactStateType.CONNECTED ||
        contact.getState()?.type === ContactStateType.ENDED) && contact.isInbound();
    });
    this._store.dispatch(SetAllContacts({
      payload: this.contacts,
    }));
  }
}
