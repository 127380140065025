import { Action, createReducer, on } from '@ngrx/store';

import * as EAnalyticsBoardItemsActions from '../actions/analytics-board-items.actions';
import { AnalyticsBoardItemsState, initializeState } from '../state/analytics-board-items.state';

const intialState = initializeState();

const reducer = createReducer(
    intialState,
    on(EAnalyticsBoardItemsActions.SetAnalyticsBoardItems, (state: AnalyticsBoardItemsState, { payload }) => {
        return { ...state, boardItems: [...payload] };
    }),
    // on(EAnalyticsBoardItemsActions.EditAnalyticsBoardItem, (state: AnalyticsBoardItemsState, { payload }) => {
    //     const index = state.boardItems.map(item => item.key).indexOf(payload.key);
    //     return {
    //         ...state, boardItems: [...state.boardItems.slice(0, index),
    //         Object.assign({}, state.boardItems[index], payload),
    //         ...state.boardItems.slice(index + 1)]
    //     };
    // })
);

export function AnalyticsBoardItemsReducer(state: AnalyticsBoardItemsState | undefined, action: Action): AnalyticsBoardItemsState {
    return reducer(state, action);
}
