import { Action, createReducer, on } from '@ngrx/store';
import { EmailsState } from '@store/state/emails.state';

import * as EmailActions from '../actions/emails.actions';
import { initializeState } from '../state/emails.state';

const intialState = initializeState();

const reducer = createReducer(
    intialState,
    on(EmailActions.SetEmails, (state: EmailsState, { payload }) => {
        return { ...state, emails: [...payload] };
    }),
    on(EmailActions.AddEmail, (state: EmailsState, { payload }) => {
        return { ...state, emails: [...state.emails, payload] };
    }),
    on(EmailActions.DeleteEmail, (state: EmailsState, { payload }) => {
        return { ...state, emails: [...state.emails.filter(email => email.id !== payload)] };
    }),
);

export function EmailsReducer(state: EmailsState | undefined, action: Action): EmailsState {
    return reducer(state, action);
}
