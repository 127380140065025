import { createAction, props } from '@ngrx/store';
import { IEmailListItem } from '@models/email';

export enum EEmailsActions {
    SetEmails = '[Emails] - Set Emails',
    AddEmail = '[Emails] - Add Emails',
    DeleteEmail = '[Emails] - Delete Email'
}

export const AddEmail = createAction(EEmailsActions.AddEmail,
    props<{ payload: IEmailListItem }>()
);

export const SetEmails = createAction(
    EEmailsActions.SetEmails,
    props<{ payload: IEmailListItem[] }>()
);

export const DeleteEmail = createAction(
    EEmailsActions.DeleteEmail,
    props<{ payload: string }>()
);
