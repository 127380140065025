import {AvailableTransfers, Transfer} from "@models/transfers";

export enum EManageCallAction {
  MAKE_CALL = 'call',
  MUTE = 'mute',
  UNMUTE = 'unmute',
  CALL_ACCEPTED = 'call_accept',
  CALL_DECLINED = 'call_declined',
  HANGUP = 'hangup',
  CALL_ENDED = 'call_ended',
  AVAILABLE_TRANSFERS_CHANGED = 'available_transfers_changed',
  TRANSFER = 'transfer',
  TRANSFER_SUCCEEDED = 'transfer_succeeded',
  TRANSFER_FAILED = 'transfer_failed',
  CLEAR = 'clear',
  SET_AVAILABLE = 'available',
  HOLD_REQUESTED = 'hold_requested',
  RESUME_REQUESTED = 'resume_requested',
  HOLD_STATUS_UPDATED = 'hold_status_updated',
  CONFERENCE_STATUS_UPDATED = 'conference_status_updated',
  LOGOUT = 'logout',
  TRY_TO_RESET_CONNECT_STATE_AS_BEST_AS_POSSIBLE = 'try_to_reset_connect_state_as_best_as_possible'
}

export interface IManageCallEvent {
  action: EManageCallAction;
  payload: any;
}

/** An enumeration listing the different high-level states that a contact can have. */
export enum EContactStateType {
  /** Indicates the contact is being initialized. */
  INIT = 'init',

  /**
   * Indicates that the contact is incoming and is waiting for acceptance.
   * This state is skipped for `ContactType.VOICE` contacts but is essential for `ContactType.QUEUE_CALLBACK` contacts.
   */
  INCOMING = 'incoming',

  /** Indicates the contact is pending. */
  PENDING = 'pending',

  /**
   * Indicates that the contact is currently connecting.
   * For `ContactType.VOICE` contacts, this is when the user will accept the incoming call.
   * For all other types, the contact will be accepted during the `ContactStateType.INCOMING` state.
   */
  CONNECTING = 'connecting',

  /** Indicates the contact is connected. */
  CONNECTED = 'connected',

  /** Indicates the contact timed out before the agent could accept it. */
  MISSED = 'missed',

  /** Indicates the contact is rejected */
  REJECTED = 'rejected',

  /** Indicates the contact is in an error state. */
  ERROR = 'error',

  /** Indicates the contact has ended. */
  ENDED = 'ended',
}

export interface CallNumberBody {
  countryCode: string;
  dialCode: string;
  e164Number: string;
  internationalNumber: string;
  nationalNumber: string;
  number: string;
}

export enum TransferType {
  WARM = 0,
  COLD = 1
}

export type TransferEventPayload = {
  transferType: TransferType,
  transfer: Transfer,
};

export type TransferSucceededEventPayload = {
  type: TransferType,
};

export type TransferFailedEventPayload = {
  error: string,
};

export type HoldStatusUpdatedEventPayload = {
  isOnHold: boolean,
};

export type ConferenceStatusUpdatedEventPayload = {
  isInConference: boolean,
};

export type AvailableTransfersChangedEventPayload = {
  availableTransfers: AvailableTransfers
};
