import { ISideNavItem } from '@models/index';

export class SideNavState {
    sideNavItems: ISideNavItem[];
    isCollapsed: boolean;
}

export const initializeState = (): SideNavState => {
    return { sideNavItems: [], isCollapsed: false };
};
