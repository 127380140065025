import { createAction, props } from '@ngrx/store';
import { ISideNavItem } from '@models/index';

export enum ESideNavActions {
    GetSideNavItems = '[SideNavItem] - Get Side Nav Items',
    SetSideNavItems = '[SideNavItem] - Set Side Nav Items',
    GetisCollapsed = '[SideNav] - Get Is Expanded bool',
    SetIsExapnded = '[SideNav] - Set Is Expanded bool',
}

export const GetSideNavItems = createAction(ESideNavActions.GetSideNavItems);

export const SetSideNavItems = createAction(
    ESideNavActions.SetSideNavItems,
    props<{ payload: ISideNavItem[] }>()
);

export const GetisCollapsed = createAction(ESideNavActions.GetisCollapsed);

export const SetisCollapsed = createAction(
    ESideNavActions.SetIsExapnded,
    props<{ payload: boolean }>()
);
