import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import de from '@angular/common/locales/de';

import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';

import {
  SideNavReducer,
  BraedCrumbsReducer,
  CallReducer,
  AnalyticsBoardItemsReducer,
  BoardItemsReducer,
  EmailsReducer,
  DocumentsReducer
} from '@store/reducers';
import { BoardItemsEffects } from '@store/effects/board-items.effects';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { en_US, de_DE, NZ_I18N } from 'ng-zorro-antd/i18n';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


import { NzIconModule } from 'ng-zorro-antd/icon';

import {
  MenuFoldOutline,
  MenuUnfoldOutline,
  DashboardOutline,
  PhoneOutline,
  ReconciliationOutline,
  UserOutline,
  LockOutline
} from '@ant-design/icons-angular/icons';

import { IconDefinition } from '@ant-design/icons-angular';

const icons: IconDefinition[] = [
  MenuFoldOutline,
  MenuUnfoldOutline,
  DashboardOutline,
  PhoneOutline,
  ReconciliationOutline,
  UserOutline,
  LockOutline
];

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import Amplify from 'aws-amplify';
import { ApiInterceptor, ErrorHandlerInterceptor } from '@interceptors/index';
import { AnalyticsFiltersReducer } from '@store/reducers/analytics-filters.reducer';
import { environment } from '@env';
import { CustomValidators } from '@shared/helpers/custom.validators';

Amplify.configure(environment.AWS.amplifyConfig);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    StoreModule.forRoot({
      sideNav: SideNavReducer,
      breadCrumbs: BraedCrumbsReducer,
      call: CallReducer,
      boardItems: BoardItemsReducer,
      analyticsBoardItems: AnalyticsBoardItemsReducer,
      analyticsFilters: AnalyticsFiltersReducer,
      emails: EmailsReducer,
      documents: DocumentsReducer
    }, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot([BoardItemsEffects]),
    StoreDevtoolsModule.instrument({
      serialize: true
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NzIconModule.forRoot(icons),
  ],
  providers: [
    {
      provide: NZ_I18N,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) => {
        registerLocaleData(de);
        registerLocaleData(en);
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    CustomValidators
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
