import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { UserAuthService } from '@services/index';
import { EUserRole } from '@models/user-auth';

import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate {

    constructor(
        private _router: Router,
        private _userAuthService: UserAuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): Observable<boolean> {
        const access = (route.data.access as EUserRole[]) || [];
        const userRole = this._userAuthService.getUserSync().attributes['custom:role'];
        const isHaveAccess = access.includes(userRole);

        if (!isHaveAccess) {
            return this._userAuthService.signOut()
                .pipe(
                    map(() => {
                        this._router.navigate(['/auth/login']);
                        return false;
                    }),
                    catchError(() => {
                        this._router.navigate(['/auth/login']);
                        return throwError(false);
                    })
                );
        }

        return of(isHaveAccess);
    }
}

