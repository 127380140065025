import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IBoardState } from '@store/state/board-items.state';

const boardItemsState = createFeatureSelector<IBoardState>('boardItems');

export const selectBoardItem = createSelector(
  boardItemsState,
  (state) => state.items.get(state.currentId)
);

export const selectCustomerContractsMap = createSelector(
  boardItemsState,
  (state) => state.items.get(state.currentId)?.customerContractsMap
);

export const selectCurrentCustomer = createSelector(
  boardItemsState,
  (state) => state.currentCustomer
);

export const selectCurrentCustomers = createSelector(
  boardItemsState,
  (state) => state.items.get(state.currentId)?.currentCustomers
);

export const selectContractDetails = createSelector(
  boardItemsState,
  (state) => state.contractDetails
);

export const selectContracts = createSelector(
  boardItemsState,
  (state) => state.items.get(state.currentId)?.contracts
);

export const selectTaskDetails = createSelector(
  boardItemsState,
  (state) => state.items.get(state.currentId)?.taskDetails
);

export const selectAdditionalData = createSelector(
  boardItemsState,
  (state) => state.items.get(state.currentId)?.connectAdditionalData
);

export const selectCurrentContactId = createSelector(
  boardItemsState,
  (state) => state.currentId
);

export const selectOutgoingContactId = createSelector(
  boardItemsState,
  (state) => state.items.get(state.currentId)?.outgoingContactId
);

export const selectBoardItems = createSelector(
  boardItemsState,
  (state) => state.items.get(state.currentId)?.boardItems
);

export const selectCurrentId = createSelector(
  boardItemsState,
  (state) => state.currentId
);

export const selectCurrentTaskType = createSelector(
  boardItemsState,
  (state) => state.items.get(state.currentId)?.taskType
);

export const selectAvailableBoardItems = createSelector(
  selectCurrentCustomer,
  selectTaskDetails,
  selectContractDetails,
  (customerData, taskDetails, contractDetails) => ({
    [customerData?.key]: customerData?.data,
    [taskDetails?.key]: taskDetails?.data,
    [contractDetails?.key]: contractDetails?.data,
  })
);
