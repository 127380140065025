import { createAction, props } from '@ngrx/store';
import { IAnalyticsBoardItem} from '@models/analytics-board-items';

export enum EAnalyticsBoardItemsActions {
    GetAnalyticsBoardItems = '[AnalyticsBoardItems] - Get AnalyticsBoardItems',
    SetAnalyticsBoardItems = '[AnalyticsBoardItems] - Set AnalyticsBoardItems',
    EditAnalyticsBoardItem = '[AnalyticsBoardItems] - Edit AnalyticsBoardItems',
}

export const GetAnalyticsBoardItems = createAction(EAnalyticsBoardItemsActions.GetAnalyticsBoardItems);

export const EditAnalyticsBoardItem = createAction(EAnalyticsBoardItemsActions.EditAnalyticsBoardItem,
    props<{ payload: IAnalyticsBoardItem }>()
);

export const SetAnalyticsBoardItems = createAction(
    EAnalyticsBoardItemsActions.SetAnalyticsBoardItems,
    props<{ payload: IAnalyticsBoardItem[] }>()
);
