import { HttpHeaders } from "@angular/common/http";

export enum EResponseStatus {
    SUCCESS = 'success',
    FAIL = 'fail'
}

export interface IApiResponse<T> {
    status: 'success' | 'fail';
    data: T;
}

export interface IApiResponseHeader {
    headers: HttpHeaders
}