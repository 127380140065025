import { ISideNavItem } from '@models/index';
import { EUserRole } from '@models/user-auth';

export const DEFAULT_NAVIGATION_ITEMS: ISideNavItem[] = [
    {
        label: 'NAVIGATION.CONNECT',
        routerLink: 'connect',
        icon: 'phone',
        access: [EUserRole.INVOICE_AGENT, EUserRole.PAYMENT_AGENT],
        // count: 5,
    },
    {
        label: 'NAVIGATION.EMAILS',
        routerLink: 'emails',
        icon: 'inbox',
        access: [EUserRole.INVOICE_AGENT, EUserRole.PAYMENT_AGENT],
        // count: 1,
    },
    {
        label: 'NAVIGATION.DOCUMENTS',
        routerLink: 'documents',
        icon: 'file-done',
        access: [EUserRole.INVOICE_AGENT, EUserRole.PAYMENT_AGENT],
        // count: 9
    },
    {
        label: 'NAVIGATION.ANALYTICS',
        routerLink: 'analytics',
        icon: 'bar-chart',
        access: [EUserRole.MANAGER_AGENT]
    },
    {
        label: 'NAVIGATION.VOICEMAIL',
        routerLink: 'voicemail',
        icon: 'audio',
        access: [EUserRole.INVOICE_AGENT, EUserRole.PAYMENT_AGENT],
        // count: 3
    },
    {
        label: 'NAVIGATION.CHAT',
        routerLink: 'chat',
        icon: 'wechat',
        access: [EUserRole.INVOICE_AGENT, EUserRole.PAYMENT_AGENT]
    },
];
