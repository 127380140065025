import { Action, createReducer, on } from '@ngrx/store';

import * as SideNavActions from '../actions/side-nav.actions';
import { SideNavState, initializeState } from '../state/side-nav.state';

const intialState = initializeState();

const reducer = createReducer(
    intialState,
    on(SideNavActions.SetSideNavItems, (state: SideNavState, { payload }) => {
        return { ...state, sideNavItems: payload };
    }),
    on(SideNavActions.SetisCollapsed, (state: SideNavState, { payload }) => {
        return { ...state, isCollapsed: payload };
    })
);

export function SideNavReducer(state: SideNavState | undefined, action: Action): SideNavState {
    return reducer(state, action);
}
