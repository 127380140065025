import { createAction, props } from '@ngrx/store';
import { IBreadCrumb } from '@models/breadcrumbs';

export enum EBreadCrumbActions {
    GetBreadCrumbs = '[BreadCrumbs] - Get BreadCrumbs',
    SetBreadCrumbs = '[BreadCrumbs] - Set BreadCrumbs',
    AddBreadCrumb = '[BreadCrumbs] - Add BreadCrumb'
}

export const GetBreadCrumbs = createAction(EBreadCrumbActions.GetBreadCrumbs);

export const AddBreadCrumb = createAction(EBreadCrumbActions.AddBreadCrumb,
    props<{ payload: IBreadCrumb }>()
);

export const SetBreadCrumbs = createAction(
    EBreadCrumbActions.SetBreadCrumbs,
    props<{ payload: IBreadCrumb[] }>()
);
