import { BoardItemDetailsKeyEnum } from '../enums';

export const DEFAULT_CONNECT_BOARD = {
  taskDetails: {
    title: 'CALL_DETAILS.TASK_DETAILS',
    key: BoardItemDetailsKeyEnum.taskDetails,
    isMinimized: false,
    errorMessage: '',
  },
  currentCustomer: {
    title: 'CALL_DETAILS.CUSTOMER_DETAILS',
    key: BoardItemDetailsKeyEnum.currentCustomer,
    isMinimized: false,
    errorMessage: '',
  },
  contractDetails: {
    title: 'CALL_DETAILS.CONTRACT_DETAILS',
    key: BoardItemDetailsKeyEnum.contractDetails,
    isMinimized: false,
    errorMessage: '',
  },
  connectAdditionalData: {
    title: 'CALL_DETAILS.BOT_CONVERSATION',
    key: BoardItemDetailsKeyEnum.connectAdditionalData,
    isMinimized: false,
    errorMessage: '',
  },
};
