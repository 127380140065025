import { createAction, props } from '@ngrx/store';
import { EContactStateType } from '@models/manage-call';
import ContactType = connect.ContactType;

export enum CallActions {
  GetAllContacts = '[Call][AllContacts] - Get AllContacts',
  SetAllContacts = '[Call][AllContacts] - Set AllContacts',
  GetAgentStatuses = '[Call][AgentStatuses] - Get AgentStatuses',
  SetAgentStatuses = '[Call][AgentStatuses] - Set AgentStatuses',
  GetDialableCountries = '[Call][AgentDialableCountries] - Get AgentDialableCountries',
  SetDialableCountries = '[Call][AgentDialableCountries] - Set AgentDialableCountries',
  SetContactType = '[Call][SetContactType] - Set ContactType',
  SetCallState = '[Call][CallState] - Set CallState',
  ClearCallDetails = '[Call][ClearCallData] - Clear Call Data',
  SetOutgoingCall = '[Call][Outgoing] - Set Outgoing',
}

export const GetAllContacts = createAction(CallActions.GetAllContacts);

export const SetAllContacts = createAction(
  CallActions.SetAllContacts,
  props<{ payload: connect.Contact[] }>()
);

export const GetAgentStatuses = createAction(CallActions.GetAgentStatuses);

export const SetAgentStatuses = createAction(
    CallActions.SetAgentStatuses,
    props<{ payload: connect.AgentStateDefinition[] }>()
);

export const GetDialableCountries = createAction(CallActions.GetDialableCountries);

export const SetDialableCountries = createAction(
  CallActions.SetDialableCountries,
  props<{ payload: string[] }>()
);

export const SetContactType = createAction(
    CallActions.SetContactType,
    props<{ payload: ContactType }>()
);

export const SetCallState = createAction(
    CallActions.SetCallState,
    props<{ payload: EContactStateType }>()
);

export const ClearCallDetails = createAction(CallActions.ClearCallDetails);

export const SetOutgoingCall = createAction(
  CallActions.SetOutgoingCall,
  props<{ payload: boolean }>()
);
