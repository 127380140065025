import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EResponseStatus } from '@models/http';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(
                map((res: HttpResponse<any>) => {
                    const status = res.status;
                    const body = res.body;

                    if (status === 200 && body?.status === EResponseStatus.FAIL) {
                        throw body;
                    }
                    return res;
                })
            );
    }
}
