import { Action, createReducer, on } from '@ngrx/store';

import * as BreadCrumbActions from '../actions/breadcrumbs.actions';
import { BreadCrumbsState, initializeState } from '../state/breadcrumbs.state';

const intialState = initializeState();

const reducer = createReducer(
    intialState,
    on(BreadCrumbActions.SetBreadCrumbs, (state: BreadCrumbsState, { payload }) => {
        return { ...state, breadcrumbs: [...payload] };
    }),
    on(BreadCrumbActions.AddBreadCrumb, (state: BreadCrumbsState, { payload }) => {
        return { ...state, breadcrumbs: [...state.breadcrumbs, payload] };
    }),
);

export function BraedCrumbsReducer(state: BreadCrumbsState | undefined, action: Action): BreadCrumbsState {
    return reducer(state, action);
}
