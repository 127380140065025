export interface IUserAuthReq {
  username: string;
  password: string;
  newPassword?: string;
  clientMetaData?: any;
  language?: string;
  confirmPasswordResetRedirectPath?: string;
}

export enum EUserRole {
  INVOICE_AGENT = 'invoice_agent',
  PAYMENT_AGENT = 'payment_agent',
  MANAGER_AGENT = 'manager_agent'
}

export interface IUser {
  attributes: {
    email: string;
    email_verified: string;
    family_name: string;
    given_name: string;
    phone_number: string;
    phone_number_verified: boolean;
    preferred_username: string;
    sub: string;
    'custom:role': EUserRole;
  };
  id: string;
  username: string;
  challengeName?: string;
}

export enum IUserLoginTry {
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
}

export interface IUserForgotPasswordReq {
  username: string;
  language: string;
  confirmPasswordResetRedirectPath: string;
}

export interface IUserForgotPasswordSetNewPasswordReq {
  username: string;
  password: string;
  token: string;
}
