import { IAnalyticsQueue, IAnalyticsStatistic } from '@models/analytics';

export class AnalyticsFiltersState {
    statistics: IAnalyticsStatistic[];
    queues: IAnalyticsQueue[];
}

export const initializeState = (): AnalyticsFiltersState => {
    return { statistics: [], queues: [] };
};
