import { Action, createReducer, on } from '@ngrx/store';
import { AnalyticsFiltersState } from '@store/state/analytics-filters.state';

import * as EAnalyticsFilterActions from '../actions/analytics-filters.actions';
import { initializeState } from '../state/analytics-filters.state';

const intialState = initializeState();

const reducer = createReducer(
    intialState,
    on(EAnalyticsFilterActions.SetAnalyticsFilterQueues, (state: AnalyticsFiltersState, { payload }) => {
        return { ...state, queues: [...payload] };
    }),
    on(EAnalyticsFilterActions.SetAnalyticsFilterStatistics, (state: AnalyticsFiltersState, { payload }) => {
        return { ...state, statistics: [...payload] };
    })
);

export function AnalyticsFiltersReducer(state: AnalyticsFiltersState | undefined, action: Action): AnalyticsFiltersState {
    return reducer(state, action);
}
