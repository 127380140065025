import { IConnectBoardItem } from '@models/board-items';
import { IContractData, ICustomerData, ITaskDetails } from '@models/customer';
import { AdditionalDataInterface } from '../../core/interfaces';
import { TaskTypeEnum } from '../../core/enums';

export class BoardItemsState {
  taskType?: TaskTypeEnum = TaskTypeEnum.Initial;
  boardItems?: IConnectBoardItem[] = [];
  customerContractsMap?: any;
  currentCustomer?: ICustomerData;
  currentCustomers?: ICustomerData[];
  contractDetails?: IContractData;
  contracts?: IContractData[];
  taskDetails?: ITaskDetails<any>;
  outgoingContactId?: string;
  connectAdditionalData?: AdditionalDataInterface;
}

export interface IBoardState {
  currentId?: string;
  currentCustomer?: ICustomerData;
  contractDetails?: IContractData;
  items: Map<string, BoardItemsState>;
}

export const initializeState = (): IBoardState => {
  return {
    currentId: '',
    items: new Map<string, BoardItemsState>(),
  };
};
