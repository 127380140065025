import { createAction, props } from '@ngrx/store';
import { IAnalyticsQueue, IAnalyticsStatistic } from '@models/analytics';

export enum EAnalyticsFilterActions {
    GetAnalyticsFilters = '[AnalyticsFilters] - Get AnalyticsFilters',
    GetAnalyticsFilterStatistics = '[AnalyticsFilters] - Get AnalyticsFilter Statistics',
    SetAnalyticsFiltersStatistics = '[AnalyticsFilters] - Set AnalyticsFilters Statistics',
    SetAnalyticsFiltersQueues = '[AnalyticsFilters] - Set AnalyticsFilters Queues',
}

export const GetAnalyticsFilters = createAction(EAnalyticsFilterActions.GetAnalyticsFilters);

export const GetAnalyticsFilterStatistics = createAction(EAnalyticsFilterActions.GetAnalyticsFilters);


export const SetAnalyticsFilterStatistics = createAction(
    EAnalyticsFilterActions.SetAnalyticsFiltersStatistics,
    props<{ payload: IAnalyticsStatistic[] }>()
);

export const SetAnalyticsFilterQueues = createAction(
    EAnalyticsFilterActions.SetAnalyticsFiltersQueues,
    props<{ payload: IAnalyticsQueue[] }>()
);
