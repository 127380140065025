import { IContractData, ICustomerData } from '@models/customer';
import { EContactStateType } from '@models/manage-call';
import { AdditionalDataInterface } from '../../core/interfaces';
import ContactType = connect.ContactType;

export class CallState {
  allContacts: connect.Contact[];
  agentStatuses: connect.AgentStateDefinition[];
  contactType: ContactType;
  callState: EContactStateType;
  currentCustomer: ICustomerData;
  contractDetails: IContractData;
  connectAdditionalData: AdditionalDataInterface;
  dialableCountries: string[];
  isOutgoingCall: boolean;
}

export const initializeState = (): CallState => {
  return {
    allContacts: [],
    agentStatuses: [],
    contactType: null,
    callState: EContactStateType.INIT,
    currentCustomer: null,
    contractDetails: null,
    connectAdditionalData: null,
    dialableCountries: [],
    isOutgoingCall: false
  };
};
