import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { environment } from '@env';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnDestroy {
  title = 'connectai-ui';

  constructor(
    private _translateService: TranslateService,
  ) {
    this._checkSelectedLanguage();
  }

  private _checkSelectedLanguage(): void {
    this._translateService.setDefaultLang(environment.translation.default);
    const languageKey = localStorage.getItem('language');
    const language = environment.translation.languages.find((e) => e.key === languageKey);
    if (language) {
      this._translateService.use(language.key);
      return;
    }
    localStorage.setItem('language', environment.translation.default);
  }

  ngOnDestroy(): void { }
}
