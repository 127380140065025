import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { UserAuthService } from '@services/index';
import { DEFAULT_NAVIGATION_ITEMS } from '@globals/side-nav';
import { IUser } from '@models/user-auth';
import { Store } from '@ngrx/store';
import { SetSideNavItems } from '@store/actions/side-nav.actions';
import { SideNavState } from '@store/state/side-nav.state';

import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _store: Store<{ sideNav: SideNavState }>,
    private _userAuthService: UserAuthService
  ) {
  }

  canActivate(): Observable<boolean> {
    return this._userAuthService.fetchUserInfo()
      .pipe(
        map((user: IUser) => {
          if (user && Object.keys(user).length > 0) {
            this._userAuthService.setUser(user);
            this._setSideNavItems();
            return true;
          }
          this._router.navigate(['/auth/login']);
          return false;
        }),
        catchError((_) => {
          this._router.navigate(['/auth/login']);
          return throwError(false);
        })
      );
  }

  private _setSideNavItems(): void {
    const userRole = this._userAuthService.getUserSync().attributes['custom:role'];
    const sideNavItems = DEFAULT_NAVIGATION_ITEMS.filter((e) => e.access.includes(userRole));
    this._store.dispatch(SetSideNavItems({payload: sideNavItems}));
  }
}

