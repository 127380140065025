import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  public loader = false;

  constructor() { }

  public showLoader(): void {
    this.loader = true;
  }

  public hideLoader(): void {
    this.loader = false;
  }

  public getLoaderStatus(): boolean {
    return this.loader;
  }

}
