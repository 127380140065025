import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ConnectService } from '@services/connect.service';
import {
  SetBoardItems,
  SetCurrentCustomer,
  SetCurrentCustomers,
  FetchCurrentCustomer,
  FetchContractDetails,
  SetContractDetails,
  SetContracts,
  FetchTaskDetails,
  SetTaskDetails,
} from '@store/actions/board-items.actions';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectCurrentId } from '@store/selectors/board-items.selectors';
import { DEFAULT_CONNECT_BOARD } from '@globals/connect';

@Injectable({
  providedIn: 'root'
})
export class BoardItemsEffects {

  constructor(
    private readonly connectService: ConnectService,
    private readonly actions$: Actions,
    private store: Store<{}>,
  ) { }

  fetchCurrentCustomer$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCurrentCustomer),
    mergeMap(action => this.connectService.getCustomerDetails(action.payload.customerId).pipe(
      switchMap((res) => {
        DEFAULT_CONNECT_BOARD.currentCustomer.errorMessage = '';
        let finalItems = [];
        this.store.select(selectCurrentId).subscribe(currentId => {
          if (Object.keys(res.data).length > 0 && currentId) {
            finalItems = [
              SetCurrentCustomers({
                payload: {
                  key: action.payload.contactId,
                  currentCustomers: [res.data]
                }
              }),
              SetBoardItems({
                payload: action.payload.contactId
              })
            ];
          }
        });
        return finalItems;
      }),
      catchError(err => {
        let finalItems = [];
        this.store.select(selectCurrentId).subscribe(currentId => {
          const errorMessage = err.status === 500
            ? 'ERRORS.SOMETHING_WENT_WRONG'
            : err.error?.errorMessage || err.error?.message;
          finalItems = [
            SetCurrentCustomers({
              payload: {
                key: action.payload.contactId,
                currentCustomers: [{
                  id: action.payload.customerId,
                  error: errorMessage || 'ERRORS.SOMETHING_WENT_WRONG',
                }]
              }
            }),
            SetBoardItems({
              payload: action.payload.contactId
            })
          ];
        });
        return finalItems;
      })
      )
    )
  ));

  fetchContractDetails$ = createEffect(() => this.actions$.pipe(
    ofType(FetchContractDetails),
    mergeMap(action => this.connectService.getContractData(action.payload.contractId, action.payload.intent).pipe(
      switchMap((res) => {
        DEFAULT_CONNECT_BOARD.contractDetails.errorMessage = '';
        let finalItems = [];
        this.store.select(selectCurrentId).subscribe(currentId => {
          if (Object.keys(res.data).length > 0 && currentId) {
            finalItems = [
              SetContracts({
                payload: {
                  key: action.payload.contactId,
                  contracts: [res.data]
                }
              }),
              SetBoardItems({
                payload: action.payload.contactId
              })
            ];
          }
        });
        return finalItems;
      }),
      catchError(err => {
        let finalItems = [];
        this.store.select(selectCurrentId).subscribe(currentId => {
          const errorMessage = err.status === 500
            ? 'ERRORS.SOMETHING_WENT_WRONG'
            : err.error?.errorMessage || err.error?.message;
          finalItems = [
            SetContracts({
              payload: {
                key: action.payload.contactId,
                contracts: [{
                  id: action.payload.contractId,
                  error: errorMessage || 'ERRORS.SOMETHING_WENT_WRONG',
                }]
              }
            }),
            SetBoardItems({
              payload: action.payload.contactId
            })
          ];
        });
        return finalItems;
      })
      )
    )
  ));

  fetchTaskDetails$ = createEffect(() => this.actions$.pipe(
    ofType(FetchTaskDetails),
    mergeMap(action => this.connectService.getTaskData(action.payload.taskId).pipe(
      switchMap((res) => {
        DEFAULT_CONNECT_BOARD.taskDetails.errorMessage = '';
        let finalItems = [];
        this.store.select(selectCurrentId).subscribe(currentId => {
          if (Object.keys(res.data).length > 0 && currentId) {
            finalItems = [
              SetTaskDetails({
                payload: {
                  key: action.payload.contactId,
                  taskDetails: res.data
                }
              }),
              SetBoardItems({
                payload: action.payload.contactId
              })
            ];
          }
        });
        return finalItems;
      }),
      catchError(err => {

        DEFAULT_CONNECT_BOARD.taskDetails.errorMessage = err.error?.errorMessage || 'UTILS.EMAIL_LIMIT_ERRORMESSAGE';
          return EMPTY;
        })
      )
    )
  ));
}
