import { Action, createReducer, on } from '@ngrx/store';

import * as EBoardItemsActions from '../actions/board-items.actions';
import { BoardItemsState, IBoardState, initializeState } from '../state/board-items.state';
import { DEFAULT_CONNECT_BOARD } from '@globals/connect';
import {BoardItemDetailsKeyEnum, TaskTypeEnum} from '../../core/enums';

const initialState = initializeState();

const reducer = createReducer(
    initialState,
    on(EBoardItemsActions.SetBoardItem, (state, { payload }) => {
      state.items.set(payload, { boardItems: [], taskType: TaskTypeEnum.Initial });
      return {
        ...state,
        currentId: payload,
        items: new Map<string, BoardItemsState>(state.items.entries())
      };
    }),
    on(EBoardItemsActions.SetCustomerContractsMap, (state, { payload }) => {
      const task = state.items.get(payload.key);
      task.customerContractsMap = { value: [...payload.customerContractsMap], key: BoardItemDetailsKeyEnum.customerContractsMap };
      state.items.set(payload.key, task);
      return {
        ...state,
        items: new Map<string, BoardItemsState>(state.items.entries())
      };
    }),
    on(EBoardItemsActions.SetCurrentCustomer, (state, { payload }) => {
      return {
        ...state,
        currentCustomer: payload.currentCustomer
      };
    }),
    on(EBoardItemsActions.SetCurrentCustomers, (state, { payload }) => {
      const task = state.items.get(payload.key) || { currentCustomers: [] };
      const existingCustomers = task.currentCustomers || [];
      task.currentCustomers = [...existingCustomers, ...payload.currentCustomers];
      state.items.set(payload.key, task );

      return {
        ...state,
        items: new Map<string, BoardItemsState>(state.items.entries())
      };
    }),
    on(EBoardItemsActions.SetContractDetails, (state, { payload }) => {
      return {
        ...state,
        contractDetails: payload.contractDetails
      };
    }),
    on(EBoardItemsActions.SetContracts, (state, { payload }) => {
      const task = state.items.get(payload.key);
      const existingContracts = task.contracts || [];

      task.contracts = [...existingContracts, ...payload.contracts];
      state.items.set(payload.key, task);

      return {
        ...state,
        items: new Map<string, BoardItemsState>(state.items.entries())
      };
    }),
    on(EBoardItemsActions.SetTaskDetails, (state, { payload }) => {
      const task = state.items.get(payload.key);
      task.taskDetails = { ...payload.taskDetails, key: BoardItemDetailsKeyEnum.taskDetails, };
      state.items.set(payload.key, task);
      return {
        ...state,
        items: new Map<string, BoardItemsState>(state.items.entries())
      };
    }),
    on(EBoardItemsActions.SetAdditionalData, (state, { payload }) => {
      const task = state.items.get(payload.key);
      task.connectAdditionalData = { ...payload.additionalData, key: BoardItemDetailsKeyEnum.connectAdditionalData, };
      state.items.set(payload.key, task);
      return {
        ...state,
        items: new Map<string, BoardItemsState>(state.items.entries())
      };
    }),
    on(EBoardItemsActions.SetCurrentContactId, (state, { payload }) => {
      return {
        ...state,
        currentId: payload
      };
    }),
    on(EBoardItemsActions.SetOutgoingContactId, (state, { payload }) => {
      const task = state.items.get(payload.key);
      task.outgoingContactId = payload.outgoingContactId;
      state.items.set(payload.key, task);
      return {
        ...state,
        items: new Map<string, BoardItemsState>(state.items.entries())
      };
    }),
    on(EBoardItemsActions.SetCurrentTaskType, (state, { payload }) => {
      const task = state.items.get(payload.key);
      task.taskType = payload.taskType;
      state.items.set(payload.key, task);
      return {
        ...state,
        items: new Map<string, BoardItemsState>(state.items.entries())
      };
    }),
    on(EBoardItemsActions.SetBoardItems, (state, { payload }) => {
      const task = state.items.get(payload);
      task.boardItems = [];
      Object.keys(DEFAULT_CONNECT_BOARD).forEach(key => {
        if (task[key]) {
          task.boardItems.push(DEFAULT_CONNECT_BOARD[key]);
        }
      });
      state.items.set(payload, task);
      return {
        ...state,
        items: new Map<string, BoardItemsState>(state.items.entries())
      };
    }),
    on(EBoardItemsActions.SetBoardItemIsMinimized, (state, { payload }) => {
      const task = { ...state.items.get(state.currentId) };
      task.boardItems = task.boardItems.map(item => payload.key === item.key ? { ...item, isMinimized: payload.isMinimized } : item);
      state.items.set(state.currentId, task);
      return {
        ...state,
        items: new Map<string, BoardItemsState>(state.items.entries())
      };
    }),
    on(EBoardItemsActions.RemoveTask, (state, { payload }) => {
      state.items.delete(state.currentId);
      state.currentId = payload;
      return {
        ...state,
        items: new Map<string, BoardItemsState>(state.items.entries())
      };
    }),
);

export function BoardItemsReducer(state, action: Action): IBoardState {
  return reducer(state, action);
}
