import { createAction, props } from '@ngrx/store';
import { IDocumentsList } from '@models/document';

export enum EDocumentsActions {
    SetDocuments = '[Documents] - Set Documents',
    AddDocument = '[Documents] - Add Documents',
    DeleteDocument = '[Documents] - Delete Document'
}

export const AddDocument = createAction(EDocumentsActions.AddDocument,
    props<{ payload: IDocumentsList }>()
);

export const SetDocuments = createAction(
    EDocumentsActions.SetDocuments,
    props<{ payload: IDocumentsList[] }>()
);

export const DeleteDocument = createAction(
    EDocumentsActions.DeleteDocument,
    props<{ payload: string }>()
);
