import { Action, createReducer, on } from '@ngrx/store';
import { DocumentsState } from '@store/state/documents.state';

import * as DocumentActions from '../actions/documents.actions';
import { initializeState } from '../state/documents.state';

const intialState = initializeState();

const reducer = createReducer(
    intialState,
    on(DocumentActions.SetDocuments, (state: DocumentsState, { payload }) => {
        return { ...state, documents: [...payload] };
    }),
    on(DocumentActions.AddDocument, (state: DocumentsState, { payload }) => {
        return { ...state, documents: [...state.documents, payload] };
    }),
    on(DocumentActions.DeleteDocument, (state: DocumentsState, { payload }) => {
        return { ...state, documents: [...state.documents.filter(document => document.DocumentID !== payload)] };
    }),
);

export function DocumentsReducer(state: DocumentsState | undefined, action: Action): DocumentsState {
    return reducer(state, action);
}
