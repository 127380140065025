import { Action, createReducer, on } from '@ngrx/store';

import * as CallActions from '../actions/call.actions';
import { CallState, initializeState } from '../state/call.state';

const initialState = initializeState();

const reducer = createReducer(
    initialState,
    on(CallActions.SetAllContacts, (state: CallState, { payload }) => {
      return { ...state, allContacts: payload };
    }),
    on(CallActions.SetAgentStatuses, (state: CallState, { payload }) => {
        return { ...state, agentStatuses: payload };
    }),
    on(CallActions.SetDialableCountries, (state: CallState, { payload }) => {
      return { ...state, dialableCountries: payload };
    }),
    on(CallActions.SetContactType, (state: CallState, { payload }) => {
        return { ...state, contactType: payload };
    }),
    on(CallActions.SetCallState, (state: CallState, { payload }) => {
        return { ...state, callState: payload };
    }),
    on(CallActions.ClearCallDetails, (state: CallState) => {
        return { ...state, contractDetails: null, currentCustomer: null };
    }),
    on(CallActions.SetOutgoingCall, (state: CallState, { payload }) => {
      return { ...state, isOutgoingCall: payload };
    }),
);

export function CallReducer(state: CallState | undefined, action: Action): CallState {
    return reducer(state, action);
}
